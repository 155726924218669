<template>
  <form-layout-provider
    #default="{ formLayout }"
    :formConfig="formConfig"
    :fieldsMeta="formMeta.fields"
  >
    <div>
      <entity-form
        :id="'TEST'"
        :type="'META_FORM_TEST'"
        :formLayout="formLayout"
        :sourceData="formData"
        :formConfig="formConfig"
        :descriptors="formMeta.fields"
        :formState="subFormState"
        canReadModel
        @buttonClick="onButtonClick"
        @dataChanged="onDataChange"
      />

      <a-alert
        v-if="errors.length"
        class="drafts__errors"
        :message="$t('metaForms.actionError')"
        :description="errors.join('<br />')"
        type="error"
      />
    </div>
  </form-layout-provider>
</template>

<script>
import { isEqual } from 'lodash';
import FormConfigService from '@/services/FormConfigService';
import FormLayoutProvider from '@/components/edit-form/FormLayoutProvider';
import EntityForm from '@/components/edit-form/EntityForm';

export default {
  components: {
    EntityForm,
    FormLayoutProvider,
  },

  props: {
    formName: {
      type: String,
      required: true,
    },
    formMeta: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    formState: {
      type: Object,
      required: true,
    },
    excludeFieldRenderers: {
      type: Array,
      default: () => ['history'],
    },
  },

  data() {
    return {
      formConfig: this.getConfig(),
      data: { ...this.formData },
      errors: [],
    };
  },

  computed: {
    subFormState() {
      const isDirty = !isEqual(this.formData, this.data);

      return {
        ...this.formState,
        handbooks: this.formMeta.handbooks,
        isDirty,
      };
    },
  },

  methods: {
    getConfig() {
      return FormConfigService.getMetaFormConfig(this.formName, {
        ...this.formMeta,
        fields: this.formMeta.fields.filter(
          (field) => !this.excludeFieldRenderers.includes(field.renderer),
        ),
      });
    },

    validateForm(dataSource) {
      const isObjectValue = typeof dataSource === 'object';
      const isConstant =
        !isObjectValue && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
      const isContextField = !isObjectValue && !isConstant && dataSource.startsWith('$ctx');
      let fieldName;

      if (isObjectValue) {
        return Object.values(dataSource)
          .map(this.validateForm)
          .flat()
          .filter((e) => e !== false);
      }

      if (isContextField) {
        fieldName = dataSource.slice(5);
      } else if (!isConstant) {
        fieldName = dataSource.slice(1);
      }

      const fieldMeta = this.formMeta.fields.find((field) => field.name === fieldName);

      if (!fieldMeta) return false;

      if (fieldMeta.required && !this.data[fieldName]) {
        return this.$t('metaForms.fieldIsRequired', { field: fieldMeta.label });
      }

      return false;
    },

    onButtonClick(data, button) {
      this.errors = this.validateForm(button.action.parameters);
      if (this.errors.length) return;

      this.$emit('buttonClick', data, button);
    },

    onDataChange(data) {
      this.data = { ...this.data, ...data };
      this.$emit('dataChanged', data);
    },
  },
};
</script>
