import polyfills from './polyfills'; // eslint-disable-line
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import Antd from 'ant-design-vue';
import VScrollLock from 'v-scroll-lock';
import VClickOutside from 'v-click-outside';
import moment from 'moment';
import { bus, XHR } from '@/helpers';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import momentRu from 'moment/locale/ru';
import LocaleService, { i18n } from './services/LocaleService';
import { apolloProvider } from './apolloProvider';

XHR.initialize(store, router);

polyfills();

Vue.config.productionTip = false;
moment.locale('ru', [momentRu]);

Vue.use(VueApollo);
Vue.use(Antd);
Vue.use(VScrollLock);
Vue.use(VClickOutside);

Vue.mixin({
  methods: {
    reemit(eventName, args = []) {
      this.$emit(eventName, ...args);
    },
    emitError(message, description) {
      bus.$emit('error', {
        message,
        description,
      });
    },
  },
});

store.mutate.setApolloClient(apolloProvider.defaultClient);

LocaleService.init().then(() => {
  new Vue({
    i18n,
    router,
    apolloProvider,
    render: (h) => h(App),
  }).$mount('#app');
});
