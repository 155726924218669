<template>
  <div class="log">
    <div class="page-block__title">
      <h1>
        {{ $t('log.title') }}
        <span
          :class="['log__connection-status', 'log__connection-status--' + socketStatus]"
          :title="socketStatusMessage[socketStatus]"
        ></span>
      </h1>
      <a-button
        class="log__clear"
        type="link"
        @click="clear"
      >
        {{ $t('log.clear') }}
      </a-button>
    </div>
    <log-viewer
      :log="logText"
      :loading="isLoading"
    />
  </div>
</template>

<script>
import LogViewer from '@femessage/log-viewer';
import store from '@/store';

export default {
  name: 'TheLogs',
  components: {
    LogViewer,
  },

  data() {
    return {
      socketStatus: 'waiting',
      socketStatusMessage: {
        connected: this.$t('log.status.connected'),
        waiting: this.$t('log.status.waiting'),
        closed: this.$t('log.status.closed'),
        error: this.$t('log.status.error'),
      },
      socket: null,
      isLoading: false,
      log: [],
    };
  },

  computed: {
    endpoint() {
      return store.state.activeSidebarItem.customprops?.endpoint;
    },

    rowsLimit() {
      return store.state.activeSidebarItem.customprops?.rowsLimit || 4000;
    },

    logText() {
      return this.log.join('\n');
    },
  },

  created() {
    this.createConnection();
  },

  beforeDestroy() {
    this.socket.close();
  },

  methods: {
    createConnection() {
      try {
        this.socket = new WebSocket(this.endpoint);
      } catch (e) {
        this.socketStatus = 'error';
        throw e;
      }

      this.socket.addEventListener('open', () => {
        this.socketStatus = 'connected';
      });

      this.socket.addEventListener('close', () => {
        this.socketStatus = 'closed';
      });

      this.socket.addEventListener('error', () => {
        this.socketStatus = 'error';
      });

      this.socket.addEventListener('message', (event) => {
        this.appendRow(event.data);
      });
    },

    appendRow(row) {
      this.log = this.log.concat(row).slice(-this.rowsLimit);
    },

    clear() {
      this.log = [];
    },
  },
};
</script>

<style lang="scss">
.log {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__clear {
    position: relative;
    right: -15px;
    top: 10px !important;
  }

  .page-block__title {
    justify-content: space-between;
    flex: 1;
  }

  .log-viewer {
    @include scrollbars();
    flex: 1 1 100%;
  }

  .log__connection-status {
    position: relative;
    top: 11px;
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid #666;
    background: #888;

    &--connected {
      display: none;
      background: #080;
    }

    &--waiting {
      background: #ee0;
    }

    &--closed,
    &--error {
      background: #e00;
    }
  }
}
</style>
