<template>
  <div class="page-block">
    <a-alert
      v-if="isPublishable && !componentMeta"
      class="page-block__error"
      :message="$t(`page.error.meta`, { type: entityType })"
      type="error"
      showIcon
    />

    <a-spin
      v-else
      :spinning="(!data && !requestError) || !config || loadingQueries > 0"
    >
      <div class="spin-wrap">
        <template v-if="fieldsMeta && config">
          <div class="page-block__title">
            <a-icon
              v-if="showSidebarButton"
              class="menu-toggle"
              type="menu"
              @click="toggleMenu"
            />
            <h1 class="ellipsis">{{ title }}</h1>
            <a-icon
              v-if="canEditModel"
              class="page-block__model-link"
              type="edit"
              @click="editModel"
            />
          </div>

          <the-filter
            v-if="filterParams"
            :pageConfig="config"
            :formConfig="formConfig"
            :filters="filterParams"
            :configData="cachedConfig"
            :configLayout="CONFIG_LAYOUT"
            :fieldsMeta="fieldsMeta"
            :entityType="entityType"
            :canCreateModel="canCreateModel"
            hideFilters
            isNewMeta
            @setFilterParams="setFilterParams"
            @createEntity="entityClick"
            @configUpdated="saveConfig"
            @pageSizeUpdated="updatePageSize"
          >
            <template
              v-if="data"
              #pagination
            >
              <template v-if="switchButton">
                <template v-for="(button, index) of switchMeta.fields">
                  <a-button
                    v-if="switchButtonIndex !== index"
                    :key="index"
                    type="primary"
                    @click="setSwitchButton(index)"
                  >
                    {{ button.label }}
                  </a-button>
                </template>
              </template>

              <a-pagination
                v-if="!data.noPagination"
                v-model="page"
                simple
                hideOnSinglePage
                :disabled="loadingQueries > 0"
                :total="data.totalCount"
                :pageSize="config.pageSize"
              />
            </template>
          </the-filter>

          <component
            :is="rendererComponent"
            v-if="data"
            :entityType="entityType"
            :entityFields="fieldsMeta"
            :defaultSort="sortParams"
            :data="data"
            :config="config"
            :formConfig="formConfig"
            :canReadModel="canReadModel"
            :canUpdateModel="canUpdateModel"
            @updateEntity="updateEntity"
            @setSortParams="setSortParams"
            @entityClick="entityClick"
            @updateRows="updateRows"
          />

          <a-alert
            v-else-if="requestError"
            type="error"
            :message="requestError"
          />
        </template>
      </div>
    </a-spin>
  </div>
</template>

<script>
import store from '@/store';
import {
  bus,
  deepClone,
  storage,
  XHR,
  parseJson,
  uniqueId,
  composeGqlQueryFromAction,
} from '@/helpers';
import { GqlObject } from '@/helpers/GqlObject';
import EntityService from '@/services/EntityService';
import FormConfigService from '@/services/FormConfigService';
import TheFilter from '@/components/page/filter/TheFilter.vue';
import TheGantt from '@/components/page/gantt/TheGantt.vue';
import TheKanban from '@/components/page/kanban/TheKanban.vue';
import TheTable from '@/components/page/table/TheTable.vue';
import TheTiles from '@/components/page/tiles/TheTiles.vue';
import TheCalendar from '@/components/page/calendar/TheCalendar.vue';
import TheMap from '@/components/page/map/TheMap.vue';
import FormsList from '@/components/page/forms-list/FormsList.vue';
import SoCourseList from '@/components/custom-entities/SoCourseList/SoCourseList.vue';
import SoPromocodes from '@/components/custom-entities/SoPromocodes/SoPromocodes.vue';
import AuroReleases from '@/components/custom-entities/AuroReleases/AuroReleases.vue';
import { CONFIG_LAYOUT, prepareConfig } from '@/components/page/config/configFormLayout.js';
import { getFilterRendererByFormRenderer } from '@/components/page/filter/fieldOperators.js';

import { debounce } from 'lodash';

const DATA_REQUEST_DEBOUNCE = 900;

export default {
  components: {
    TheFilter,
    TheGantt,
    TheKanban,
    TheTable,
    TheInlineTable: TheTable,
    TheTiles,
    TheCalendar,
    TheMap,
    FormsList,
    SoCourseList,
    SoPromocodes,
    AuroReleases,
  },

  props: {
    pageUrl: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    showSidebarButton: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadingQueries: 0,
      CONFIG_LAYOUT,
      page: Number(this.$route.query.page) || 1,
      filterParams: null,
      sortParams: [],
      cachedConfig: null,
      requestError: null,
      queryPageSize: Number(this.$route.query.pageSize),
      configData: this.getBaseConfig(),

      data: null,
      switchMeta: null,
      switchButtonIndex: null,
      meta: null,
      listMeta: null,
    };
  },

  computed: {
    componentMeta() {
      return store.state.meta.components[this.entityType];
    },
    user() {
      return store.state.user;
    },
    canEditModel() {
      return (
        this.user.isConstructor &&
        !!store.state.meta.entities.find((e) => e.name === this.entityType)
      );
    },
    customForms() {
      return (
        store.state.activeSidebarItem.customprops?.form?.actions?.map((form) =>
          typeof form === 'string' ? { action: form } : form,
        ) || []
      );
    },
    desktop() {
      return store.state.isDesktop;
    },
    config() {
      return this.cachedConfig?.config;
    },
    formConfig() {
      if (this.isPublishable) {
        return FormConfigService.getFormConfig(this.entityType);
      }

      return (
        this.meta &&
        FormConfigService.getMetaFormConfig(store.state.activeSidebarItem.id, this.meta)
      );
    },
    title() {
      return (
        this.formConfig.locale[store.state.lang].entity ||
        store.state.activeSidebarItem.title ||
        this.entityType
      );
    },
    rendererComponent() {
      const customComponent = store.state.activeSidebarItem.customprops?.customComponent;
      if (customComponent) return customComponent;
      if (this.customForms.length) return 'forms-list';
      return `the-${this.config.displayType.value}`;
    },
    isTable() {
      return this.config.displayType.value === 'table';
    },
    operations() {
      return !this.isPublishable
        ? {
            CREATE: true,
            READ: true,
            UPDATE: true,
          }
        : this.componentMeta?.operations || {};
    },
    canCreateModel() {
      return this.operations.CREATE && !this.customForms.length;
    },
    canReadModel() {
      return this.operations.READ;
    },
    canUpdateModel() {
      return this.operations.UPDATE;
    },

    isPublishable() {
      return !store.state.activeSidebarItem.customprops?.meta;
    },

    dataActions() {
      let sourcesKeys = this.listMeta.fields.map(
        (field) => field.source?.match(/^\$([a-zA-Z_]+?)\./)[1] || 'ctx',
      );

      sourcesKeys = [...new Set(sourcesKeys)].filter((key) => key !== 'ctx');
      return sourcesKeys.map((key) => this.listMeta[key]);
    },

    switchButton() {
      return this.switchMeta?.fields[this.switchButtonIndex];
    },

    fieldsMeta() {
      if (this.isPublishable) {
        return this.listMeta?.fields;
      }

      return this.meta?.fields;
    },

    limit() {
      return this.config.pageSize;
    },

    offset() {
      return this.config.pageSize * (this.page - 1);
    },

    order() {
      return new GqlObject(
        this.sortParams,
        (object) => `{field: "${object.field}", direction: ${object.direction}}`,
      );
    },

    where() {
      return {};
    },
  },

  watch: {
    $route(to, from) {
      if (to.path === from.path && to.fullPath !== from.fullPath) {
        this.filterParams = null;
        this.sortParams = [];
        this.$nextTick(() => {
          this.sortParams = this.getInitialSortParams();
          this.filterParams = this.getInitialFilterParams();
        });
      }
    },
    page() {
      this.updatePageQueryParams();
    },
    formConfig: {
      deep: true,
      handler() {
        this.resetConfig();
      },
    },
  },

  async created() {
    this.createRefetchDataMethod();
    bus.$on('refetchTable', this.refetchData);

    this.loadingQueries++;
    await this.loadMeta();
    await this.loadSwitchMeta();
    await this.resetConfig();

    this.sortParams = this.getInitialSortParams();
    this.filterParams = this.getInitialFilterParams();

    this.setSwitchButton(0);
    this.loadingQueries--;
  },

  destroyed() {
    bus.$off('refetchTable', this.refetchData);
  },

  methods: {
    async loadMeta() {
      if (!this.isPublishable) {
        const metaUrl = store.state.activeSidebarItem.customprops?.meta;
        const meta = await XHR.get(metaUrl);
        this.meta = parseJson(meta);

        const url = this.meta.tableBarField.searchButton.action;

        const response = await XHR.get(url, { absoluteUrl: true });
        this.listMeta = parseJson(response);
        return;
      }

      const listMetaUrl = store.state.activeSidebarItem.customprops?.listMeta;
      const response = await XHR.get(listMetaUrl);
      this.listMeta = parseJson(response);
    },

    async loadSwitchMeta() {
      const url = store.state.activeSidebarItem.customprops?.switchMeta;
      if (!url) return;
      const response = await XHR.get(url);
      this.switchMeta = parseJson(response);
    },

    async setSwitchButton(index) {
      this.switchButtonIndex = index;
      await this.loadData();
    },

    createRefetchDataMethod() {
      this.refetchData = debounce(() => {
        this.loadData();
      }, DATA_REQUEST_DEBOUNCE);
    },

    async loadData() {
      this.loadingQueries++;
      const loadedData = await Promise.all(
        this.dataActions.map((action) => this.loadDataSource(action)),
      );

      const combinedData = loadedData.reduce(
        (acc, data) => {
          acc.totalCount += data.totalCount;
          acc.totalPages = Math.max(acc.totalPages, data.totalPages);
          acc.hasMore = data.hasMore || data.hasMore;
          acc.rows = acc.rows.concat(data.rows);

          acc.noPagination = acc.noPagination || data.noPagination;

          Object.assign(acc, data);
          return acc;
        },
        {
          totalCount: 0,
          totalPages: 0,
          hasMore: false,
          rows: [],
          noPagination: false,
        },
      );

      this.data = combinedData;
      this.loadingQueries--;
    },

    async loadDataSource(action) {
      if (action.type === 'GRAPHQL') {
        return this.loadGraphData(action);
      }

      if (action.type === 'REST') {
        return this.loadRestData(action);
      }

      throw new Error('Unknown action type');
    },

    async loadRestData(action) {
      return new Promise((resolve, reject) => {
        this.loadingQueries++;
        let url = action.url;

        if (action.params?.length) {
          if (action.paramsType === 'path') {
            url = action.params.reduce(
              (acc, name) => `${acc}/${this.$route.params.rowData[name]}`,
              url,
            );
          }
        }

        XHR.query(action.method, url, {
          absoluteUrl: true,
        }).then(
          (data) => {
            data = parseJson(data);

            data = {
              noPagination: true,
              totalCount: data.length,
              totalPages: 1,
              hasMore: false,
              rows: data.map((itemData) => ({
                id: itemData.id ?? uniqueId('vid'),
                data: itemData,
              })),
            };

            this.loadingQueries--;
            resolve(data);
          },
          (error) => {
            this.$notification.error({
              message: error,
            });

            this.loadingQueries--;
            reject();
          },
        );
      });
    },

    async loadGraphData(action) {
      let { data } = await this.$apollo.query({
        query: composeGqlQueryFromAction(action, {}, this, this.fieldsMeta),
        client: 'api2client',
        fetchPolicy: 'network-only',
      });

      data = action.path
        .split('.')
        .slice(1)
        .reduce((temp, key) => temp[key], data);

      return {
        totalCount: data.totalCount,
        totalPages: Math.ceil(data.totalCount / this.config.pageSize),
        hasMore: data.hasMore,
        rows: data.data.map((itemData) => ({ id: itemData.id, data: itemData })),
      };
    },

    async resetConfig() {
      let configData = deepClone(this.configData);
      if (configData) {
        configData = {
          ...configData,
          config: await prepareConfig(
            configData.config,
            this.fieldsMeta,
            CONFIG_LAYOUT,
            this.entityType,
          ),
        };
      }

      this.cachedConfig = configData;
    },

    routeQueryUpdate(queryParamsToAdd, paramsFilter = () => true) {
      const queryParamsToKeep = Object.entries(this.$route.query).filter(paramsFilter);

      this.$router
        .replace({
          query: {
            ...Object.fromEntries(queryParamsToKeep),
            ...queryParamsToAdd,
          },
        })
        .catch(() => {});

      this.loadData();
    },

    updatePageQueryParams() {
      this.routeQueryUpdate(
        {
          page: this.page,
          pageSize: this.cachedConfig.config.pageSize,
        },
        ([key]) => !['page', 'pageSize'].includes(key),
      );
    },

    refetchTable(type) {
      if (!type || type === this.entityType) {
        this.requestError = null;
        this.$apollo.queries.data.refresh();
      }
    },

    getBaseConfig() {
      const storedConfig = (storage.get('viewConfigData') || {})[this.pageUrl] || {};
      return {
        title: `pageblock_${this.entityType}`,
        config: {
          ...storedConfig,
          pageSize: this.queryPageSize || storedConfig.pageSize,
        },
      };
    },

    getInitialSortParams() {
      const queryParams = this.isTable && this.$route.query;
      let sortParams = (this.isTable && storage.get(`tableSorting_${this.pageUrl}`)) || [];
      if (queryParams.sort) {
        sortParams = [
          {
            field: queryParams.sort,
            direction: (queryParams.sortDir || '').toUpperCase(),
          },
        ];
      }

      return sortParams;
    },

    parseFilterParamValue(key, value, isArray = true) {
      const fieldExist = this.fieldsMeta.find((field) => field.name === key.split('f_')[1]);
      if (!fieldExist) return null;

      if (isArray) {
        value = value.slice(1, -1).split(/;/);
        const list = value.map((v) => this.parseFilterParamValue(key, v, false));

        return {
          field: key.split('f_')[1],
          operator: list.map((i) => i.operator),
          value: list.map((i) => i.value),
        };
      }

      value = value.split(/\./);
      let operator = value.shift();
      if (operator === 'o') {
        operator = value.shift();
        value = {
          value: value.shift(),
          title: value.join('.'),
        };
      } else {
        value = value.join('.');
      }

      const { valueProcessor = (v) => v } =
        getFilterRendererByFormRenderer(
          this.fieldsMeta.find((f) => f.name === key.split('f_')[1]).renderer,
        ) || {};

      return {
        field: key.split('f_')[1],
        operator,
        value: valueProcessor(value),
      };
    },

    getInitialFilterParams() {
      let filterParams = storage.get(`tableFiltering_${this.pageUrl}`) || [];

      // Если есть фильтры в адресной строке, берём их вместо сохранённых
      const queryFilterParams = Object.entries(this.$route.query).filter(([key]) =>
        key.startsWith('f_'),
      );

      if (queryFilterParams.length) {
        filterParams = queryFilterParams
          .map(([key, value]) => this.parseFilterParamValue(key, value))
          .filter((filterItem) => !!filterItem);
      } else {
        // Исключаем сохранённые фильтры по несуществующим полям
        filterParams = filterParams.filter((filterItem) =>
          this.fieldsMeta.find((field) => field.name === filterItem.field),
        );
      }

      return filterParams;
    },

    setSortParams(params) {
      storage.set(`tableSorting_${this.pageUrl}`, params);
      this.sortParams = params;
      const queryParamsToAdd = {
        sort: params[0]?.field,
        sortDir: params[0]?.direction.toLowerCase(),
      };

      this.routeQueryUpdate(queryParamsToAdd);
    },

    getQueryParamForFilterItem({ field, operator, value }) {
      const list = value.map((v, index) => {
        if (typeof v === 'object') {
          v = `${v.value}.${v.title}`;
          return `o.${operator[index]}.${v}`;
        }

        return `${operator[index]}.${v}`;
      });

      return [`f_${field}`, `[${list.join(';')}]`];
    },

    setFilterParams(params, initialApply = false) {
      storage.set(`tableFiltering_${this.pageUrl}`, params);
      if (!initialApply) {
        this.filterParams = params;
        this.page = 1;
      }

      const queryParamsToAdd = params.map(this.getQueryParamForFilterItem);
      this.routeQueryUpdate(Object.fromEntries(queryParamsToAdd), ([key]) => !key.startsWith('f_'));
    },

    async entityClick(id) {
      let error = null;
      const isCreating = !id;

      if (!isCreating && !this.canReadModel) {
        if (!this.canReadModel)
          error = this.$t('entity.error.noReadRights', { name: this.entityType });
      }

      if (isCreating) {
        if (!this.canCreateModel)
          error = this.$t('entity.error.noCreateRights', { name: this.entityType });
        else id = id || `_temp_${+new Date()}`;
      }

      if (error) {
        return bus.$emit('error', {
          message: error,
          type: 'warning',
        });
      }

      this.$emit(
        'entityClick',
        this.$route.params.type,
        id,
        false,
        false,
        isCreating ? {} : this.data.rows.find((row) => row.id === id).data,
      );
    },

    async updateEntity(id, data, updatedData) {
      if (!this.canUpdateModel) return;

      const draft = store.mutate.getFormDraft(this.entityType, id);
      if (draft) Object.assign(draft.data, updatedData);

      await EntityService.update(this.fieldsMeta, {
        id,
        type: this.entityType,
        data,
      });
    },

    toggleMenu() {
      bus.$emit('toggleMenu');
    },

    updateRows(rows) {
      this.data.rows = rows;
    },

    saveConfig() {
      const storaged = {};
      const storagedKeys = ['displayFields', 'displayType', 'pageSize'];

      Object.entries(this.config).forEach(([key, value]) => {
        if (storagedKeys.includes(key)) {
          storaged[key] = value;
        }
      });

      Object.assign(this.configData.config, storaged);

      const defaultStorage = storage.get('viewConfigData') || {};
      storage.set('viewConfigData', {
        ...defaultStorage,
        [this.pageUrl]: storaged,
      });
    },

    updatePageSize() {
      this.page = 1;
      this.saveConfig();
      this.updatePageQueryParams();
    },

    editModel() {
      this.$router.push({
        name: 'DataPageModel',
        params: {
          type: this.entityType,
        },
      });
    },
  },
};
</script>
