import { appSettings } from '@/AppSettings';

export function isSystemField({ types }) {
  return types?.some((type) => type.startsWith(appSettings.get('systemFieldsPrefix')));
}

export function getFieldLabel(dict, { name, types, label }, lang) {
  label = label || dict?.[name]?.label || name;
  if (isSystemField({ types })) label = appSettings.locale[lang]?.[types[0]] || label;
  return label;
}

export function getFieldInfo(dict, { name, types }) {
  const info = dict?.[name]?.info || null;
  if (isSystemField({ types })) return null;
  return info;
}
