<template>
  <div class="calendar-config">
    <a-popover
      v-model="showConfig"
      trigger="click"
      placement="bottomLeft"
    >
      <a-button
        class="type-config__button"
        :size="controlsSize"
      >
        <a-icon type="calendar" />
      </a-button>
      <template #content>
        <div class="calendar-config__popover">
          <page-block-config-form
            v-model="value"
            :entityType="entityType"
            :configLayout="getSubFormLayoutFromList(configLayout.displayType.options, 'calendar')"
            :entityFields="entityFields"
            wideLabels
            @configUpdated="configUpdated"
          />
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import PageBlockConfigForm from '@/components/page/pageblock/PageBlockConfigForm.vue';
import configFormMixin from './configFormMixin';

export default {
  name: 'CalendarConfig',
  mixins: [configFormMixin],
  components: {
    PageBlockConfigForm,
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
    controlsSize: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      showConfig: false,
    };
  },
};
</script>

<style lang="scss">
.calendar-config {
  height: 100%;

  &__popover {
    width: 300px;
  }
}
</style>
