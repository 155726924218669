<template>
  <div v-if="isVisible">
    <a-button
      :disabled="isDisabled"
      type="primary"
      @click="onClick"
    >
      {{ config.label }}
    </a-button>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    formState: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isDisabled() {
      const form = this.formState;
      const type = this.config?.conf?.type;

      if (type === 'update') return !form.isDirty;
      if (type === 'review')
        return (form.isCurrentVersion && form.isPublished) || form.isNeedsReview;
      if (type === 'decline') return !form.isNeedsReview;

      return false;
    },

    isVisible() {
      const form = this.formState;
      const type = this.config?.conf?.type;

      if (type === 'public') return !(form.isCurrentVersion && form.isPublished);
      if (type === 'unpublish') return form.isCurrentVersion && form.isPublished;

      return true;
    },
  },

  methods: {
    onClick() {
      this.$emit('buttonClick', this.config);
    },
  },
};
</script>
