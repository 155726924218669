<template>
  <div class="publishable-history">
    <div
      v-for="entry of history"
      :key="entry.j_version"
      :class="[
        'publishable-history__entry',
        { 'publishable-history__entry--active': selectedVersion === entry.j_version },
      ]"
      @click="$emit('selectVersion', entry)"
    >
      <div>
        {{ formatDateTime(entry.j_created_at, 'DD.MM.yyyy hh:mm:ss') }}

        <a-tooltip
          v-if="entry.j_user"
          :title="entry.j_user"
          :getPopupContainer="getPopupContainer"
        >
          <a-icon
            class="publishable-history__icon"
            type="user"
          />
        </a-tooltip>

        <a-tooltip
          v-if="currentVersion === entry.j_version && published"
          :title="$t('history.currentVersion')"
          :getPopupContainer="getPopupContainer"
        >
          <a-icon
            class="publishable-history__icon"
            type="check-square"
          />
        </a-tooltip>

        <a-tooltip
          v-if="entry.j_needs_review"
          :title="$t('history.needsRevies')"
          :getPopupContainer="getPopupContainer"
        >
          <a-icon
            class="publishable-history__icon publishable-history__icon--review"
            type="exclamation-circle"
          />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@/helpers';

export default {
  props: {
    selectedVersion: {
      type: String,
      default: null,
    },
    currentVersion: {
      type: String,
      default: null,
    },
    history: {
      type: Array,
      required: true,
    },
    published: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    formatDateTime,

    getPopupContainer() {
      return document.querySelector('.publishable');
    },
  },
};
</script>

<style lang="scss">
.publishable-history {
  position: relative;
  margin-top: -5px;
  margin-left: -5px;

  &::before {
    z-index: 1;
    position: absolute;
    display: block;
    right: 20px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #e0e0e0;
    content: '';
  }

  &__entry {
    z-index: 2;
    position: relative;
    margin-top: 0 !important;
    padding: 5px 5px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: transparent #f9f9f9 transparent transparent;
    line-height: 16px;
    cursor: pointer;

    &::after {
      position: absolute;
      display: block;
      right: -1px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #e0e0e0;
      content: '';
    }

    &--active {
      border-top-color: #e0e0e0;
      border-bottom-color: #e0e0e0;
      color: #40a9ff;

      &::after {
        background-color: #f9f9f9;
      }
    }
  }

  &__icon {
    margin-left: 5px;
  }

  &__icon:not(.publishable-history__icon--review) {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  &__icon--review {
    color: #f08000 !important;
  }
}
</style>
