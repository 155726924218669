<template>
  <!-- eslint-disable vuejs-accessibility/media-has-caption -->
  <div class="video">
    <video
      ref="previewVideo"
      :preload="preload"
      :src="path"
      :height="height"
      :poster="poster"
      @click="showPreview"
    >
      {{ $t('noEmbeddedVideoSupport.message') }}
      <a :href="path">{{ $t('noEmbeddedVideoSupport.linkText') }}</a>
    </video>
    <a-modal
      :visible="activePreview"
      :footer="null"
      :bodyStyle="modalBodyStyle"
      :closable="!videoLoading"
      centered
      @cancel="closePreview"
    >
      <a-spin
        class="video-modal__loader"
        :spinning="videoLoading"
      >
        <video
          v-show="!videoLoading"
          ref="video"
          controls
          autoplay
          :src="path"
          @loadedmetadata="videoLoading = false"
        >
          {{ $t('noEmbeddedVideoSupport.message') }}
          <a :href="path">{{ $t('noEmbeddedVideoSupport.linkText') }}</a>
        </video>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    path: {
      type: String,
      required: true,
    },
    preload: {
      type: String,
      default: 'metadata',
    },
    poster: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activePreview: false,
      videoLoading: true,
      modalBodyStyle: {
        maxWidth: '1170px',
        width: '100%',
      },
      imgs: [],
    };
  },
  mounted() {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      this.$refs.previewVideo.controls = true;
      this.$refs.previewVideo.src = `${this.path}#t=0.1`;
    }
  },
  methods: {
    showPreview() {
      this.$refs.previewVideo.pause();
      if (!this.disabled) {
        this.activePreview = true;
      }
    },
    closePreview() {
      this.$refs.video.pause();
      this.activePreview = false;
    },
  },
};
</script>

<style lang="scss">
.video {
  display: inline-block;
  width: 100%;

  video {
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}

.video-modal__loader {
  min-width: 300px;
  min-height: 200px;

  video {
    max-height: 100%;
    max-width: 100%;
    display: block;
  }
}
</style>
