<template>
  <draggable-list
    :value="value"
    class="pageblock-fields-list"
    @change="changeColumns"
  >
    <div
      v-for="column in list"
      :key="column.value"
      class="filter__titles pageblock-fields-list__field"
    >
      <span>{{ label(column) }}</span>
      <a-icon
        :type="columnVisibilityIcon(column)"
        @click.stop="toggleColumnVisibility(column)"
      />
    </div>
  </draggable-list>
</template>

<script>
import store from '@/store';
import { getFieldLabel } from '@/helpers';
import DraggableList from '@/components/base/DraggableList.vue';
import FormConfigService from '@/services/FormConfigService';

export default {
  name: 'PageConfigFieldsList',
  components: { DraggableList },
  props: {
    value: {
      type: Array,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    entityFields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    list: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    label(field) {
      return getFieldLabel(
        FormConfigService.getFormConfig(this.entityType).locale[store.state.lang].fields,
        field,
        store.state.lang,
      );
    },
    changeColumns({ moved }) {
      if (moved) {
        this.list.splice(moved.newIndex, 0, this.list.splice(moved.oldIndex, 1)[0]);
      }
      this.$emit('configUpdated');
    },
    columnVisibilityIcon(column) {
      return column.hidden ? 'eye-invisible' : 'eye';
    },
    toggleColumnVisibility(column) {
      column.hidden = !column.hidden;
      this.$emit('configUpdated');
    },
  },
};
</script>

<style lang="scss">
.pageblock-fields-list {
  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: all-scroll !important;
    min-width: 220px;
    white-space: nowrap;

    span {
      margin-right: 20px;
    }
  }
}
</style>
