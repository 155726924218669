import VueI18n from 'vue-i18n';
import Vue from 'vue';
import store from '@/store';
import CONFIG_QUERY from '@/queries/config';
import TABLE_QUERY from '@/queries/table';
import { storage } from '@/helpers/storage';
import BaseService from './BaseService';
import { parseJson } from '../helpers/json';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'ru',

  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

class LocaleService extends BaseService {
  constructor() {
    super();
    this.languages = [];
    this.loadedLanguages = [];
  }

  get hasMultipleLanguages() {
    return this.languages.length > 1;
  }

  async init() {
    await this.loadLanguagesList();
    let lang = storage.get('lang');

    if (!this.languages.length) {
      this.languages = [{ code: 'ru' }];
    }

    if (!this.languages.includes(lang)) {
      lang = this.languages[0].code;
    }

    store.mutate.setLang(lang);
    await this.loadLanguage(store.state.lang);
    return Promise.resolve();
  }

  async loadLanguagesList() {
    const { data } = await store.state.apolloClient.query({
      ...TABLE_QUERY,
      variables: {
        type: 'Config',
        page: 0,
        pageSize: 999,
        filters: [
          {
            field: 'title',
            operator: 'ILIKE',
            value: 'localization_',
          },
        ],
      },
    });

    this.languages = data.table.documents.map((document) => ({
      code: document.data.title.split('localization_')[1],
      name: document.data.config.languageName,
    }));
  }

  switchLang(lang) {
    const prevLang = store.state;
    store.mutate.setLang(lang);

    this.loadLanguage(lang).then(
      () => {
        this.setI18nLanguage(lang);
      },
      () => {
        store.mutate.setLang(prevLang);
      },
    );
  }

  setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
  }

  async loadLanguage(lang) {
    if (this.loadedLanguages.includes(lang)) {
      return Promise.resolve();
    }

    const { data } = await store.state.apolloClient.query({
      ...CONFIG_QUERY,
      variables: { name: `localization_${lang}` },
    });

    let messages = {};
    if (data.table.documents[0]?.data?.config) {
      try {
        messages = parseJson(data.table.documents[0].data.config);
      } catch (error) {
        const errorText = `Invalid localization JSON (${lang})`;
        this.emitError(errorText, error.message, 'warning');
        return Promise.reject(error);
      }
    }

    i18n.setLocaleMessage(lang, messages);
    this.loadedLanguages.push(lang);
    return Promise.resolve();
  }
}

export default new LocaleService();

export { i18n };
