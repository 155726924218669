import gql from 'graphql-tag';
import { parseJson } from './json';
import { GqlObject } from './GqlObject';

/**
 * Для запроса /meta
 */
class MetaQueryParser {
  prepareMeta(meta, user) {
    Object.values(meta.components).forEach((component) =>
      this.prepareMetaObject(component, meta, user),
    );

    Object.values(meta.embeds).forEach((embed) => this.prepareMetaObject(embed, meta, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
    return meta;
  }

  prepareMetaObject(object, meta, user) {
    object.fields.forEach((field) => {
      if (!['component', 'embed'].includes(field.renderer)) return;

      const fieldIsEmbed = field.renderer === 'embed';
      const childDict = fieldIsEmbed ? 'embeds' : 'components';

      field.typesDict = field.types.reduce((dict, type) => {
        dict[type] = meta[childDict][type];
        return dict;
      }, {});
    });

    this.updateObjectOperations(object, user);
    return object;
  }

  updateObjectOperations(object, user) {
    object.operations = ['TABLE', 'READ', 'UPDATE', 'CREATE', 'DELETE'].reduce((list, opName) => {
      const opRule = object.securityRules.find((rule) => rule.operation === opName);
      const roles = opRule?.roles || [];
      list[opName] = !roles.length || roles.some((role) => user.roles.includes(role));
      return list;
    }, {});
  }

  updateOperations(meta, user) {
    Object.values(meta.components).forEach((comp) => this.updateObjectOperations(comp, user));
    Object.values(meta.embeds).forEach((embed) => this.updateObjectOperations(embed, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
  }
}

export const metaQueryParser = new MetaQueryParser();

function getFieldValueForGraph(
  value,
  fieldsMeta = null,
  fieldName = null,
  gqlObject = false,
  mapper = null,
) {
  const fieldMeta = fieldsMeta?.find((field) => field.name === fieldName);

  if (value === undefined) return value;

  if (fieldMeta?.renderer === 'hidden') {
    value = parseJson(value);
    if (value === undefined) return value;
  }

  if (fieldMeta?.conf?.handbooks) {
    return fieldMeta.multiple ? `[${value.join(', ')}]` : value;
  }

  if (value instanceof GqlObject) {
    mapper = value.mapper;
    value = value.value;
    gqlObject = true;
  }

  if (gqlObject) {
    if (Array.isArray(value)) {
      const list = value
        .map((item) => getFieldValueForGraph(item, null, null, true, mapper))
        .filter((v) => v !== undefined);
      return `[${list.join(', ')}]`;
    }

    if (value && typeof value === 'object') {
      if (mapper) {
        return mapper(value);
      }

      const list = Object.keys(value).reduce((acc, key) => {
        const itemValue = getFieldValueForGraph(value[key], fieldsMeta, null, null, true);
        if (itemValue !== undefined) {
          acc.push(`${key}: ${itemValue}`);
        }

        return acc;
      }, []);

      return `{${list.join(', ')}}`;
    }

    if (value === undefined) return value;
    return JSON.stringify(value);
  }

  if (value && typeof value === 'object' && !Array.isArray(value)) {
    const list = Object.keys(value).reduce((acc, key) => {
      const itemValue = getFieldValueForGraph(value[key], fieldsMeta);
      if (itemValue !== undefined) {
        acc.push(`${key}: ${itemValue}`);
      }
      return acc;
    }, []);

    return `{${list.join(', ')}}`;
  }

  return JSON.stringify(value);
}

function composeGqlQueryParameter(dataKey, dataSource, formData, context, fieldsMeta) {
  const isObjectValue = typeof dataSource === 'object';
  const isConstant =
    !isObjectValue && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
  const isContextField = !isObjectValue && !isConstant && dataSource.startsWith('$ctx');
  let value;

  if (isObjectValue) {
    const subfieldsValues = Object.entries(dataSource)
      .map(([subDataKey, subDataSource]) =>
        composeGqlQueryParameter(subDataKey, subDataSource, formData, context, fieldsMeta),
      )
      .filter((v) => v !== undefined);

    value = `{${subfieldsValues.join(', ')}}`;
  } else if (isConstant) {
    value = getFieldValueForGraph(dataSource);
  } else if (isContextField) {
    value = getFieldValueForGraph(context[dataSource.slice(5)]);
  } else {
    value = getFieldValueForGraph(formData[dataSource.slice(1)], fieldsMeta, dataSource.slice(1));
  }

  if (value === undefined) return value;
  return `${dataKey}: ${value}`;
}

function composeGqlQueryParametersList(action, data, context, fieldsMeta) {
  return Object.entries(action.parameters)
    .map(([dataKey, dataSource]) =>
      composeGqlQueryParameter(dataKey, dataSource, data, context, fieldsMeta),
    )
    .filter((v) => v !== undefined)
    .join(', ');
}

export function composeGqlQueryFromAction(action, data, context, fieldsMeta) {
  const responseVars = action.responseVars
    ?.map((field) => field.replaceAll(/: \$.*?(,|})/g, '$1').replaceAll(':', ''))
    .join(', ');

  const query = action.path
    .split('.')
    .reverse()
    .reduce((acc, slug, index) => {
      if (index === 0) {
        slug = `${slug}(${composeGqlQueryParametersList(action, data, context, fieldsMeta)})`;
      }

      if (acc) return `${slug} {${acc}}`;
      return slug;
    }, responseVars);

  return gql`
    ${query}
  `;
}
