<template>
  <div class="draft-page">
    <form-layout-provider
      #default="{ formLayout: formLayout }"
      :formConfig="formConfig"
      :fieldsMeta="fieldsMeta"
    >
      <entity-view-compare
        v-if="draftData"
        :type="type"
        :formConfig="formConfig"
        :data="entityDataA"
        :data2="entityDataB"
        showBackButton
        @goBack="goBack"
      >
        <template #form>
          <a-spin :spinning="!formLayout || entityDataA === undefined">
            <entity-form
              v-if="formLayout && entityDataA"
              :id="id"
              :type="type"
              :formLayout="formLayout"
              :sourceData="entityDataA"
              :formConfig="formConfig"
              :descriptors="fieldsMeta"
              size="small"
              oneColumn
              readOnly
              @editEntity="editEntity"
            >
              <template #bottomControls="{ save }">
                <div class="draft-page__footer">
                  <a-button
                    type="primary"
                    @click="saveDraft(save)"
                  >
                    {{ $t('drafts.save') }}
                  </a-button>
                  <a-button
                    type="primary"
                    @click="gotoEdit"
                  >
                    {{ $t('drafts.edit') }}
                  </a-button>
                  <a-button
                    type="danger"
                    @click="dropDraft"
                  >
                    {{ $t('drafts.reset') }}
                  </a-button>
                </div>
              </template>
            </entity-form>
          </a-spin>
        </template>
        <template #compareForm>
          <div>
            <div
              v-if="isLocalDraft"
              class="entity-view__no-form-msg"
            >
              <a-alert :message="$t('drafts.newEntityDraft')" />
              <a-button
                type="primary"
                @click="dropDraft"
              >
                {{ $t('drafts.deleteNewEntityDraft') }}
              </a-button>
            </div>
            <template v-else>
              <div>
                <entity-form
                  v-if="entityDataB"
                  :id="id"
                  :type="type"
                  :formLayout="formLayout"
                  :sourceData="entityDataB"
                  :formConfig="formConfig"
                  :descriptors="fieldsMeta"
                  readOnly
                  oneColumn
                  size="small"
                  @editEntity="editEntity"
                />
              </div>
            </template>
          </div>
        </template>
      </entity-view-compare>
    </form-layout-provider>
  </div>
</template>

<script>
import store from '@/store';
import GET_QUERY from '@/queries/get';
import FormConfigService from '@/services/FormConfigService';
import EntityForm from '../edit-form/EntityForm.vue';
import EntityViewCompare from '../edit-form/EntityViewCompare.vue';
import FormLayoutProvider from '../edit-form/FormLayoutProvider.vue';

export default {
  name: 'TheDraftComparePage',
  components: {
    FormLayoutProvider,
    EntityForm,
    EntityViewCompare,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  apollo: {
    sourceData: {
      ...GET_QUERY,
      variables() {
        return {
          id: this.id,
          type: this.type,
        };
      },
      skip() {
        return this.isLocalDraft || !this.draftData;
      },
      error(error) {
        this.emitError(this.$t('entity.error.get'), error.message);
      },
    },
  },

  data() {
    return {
      formConfig: FormConfigService.getFormConfig(this.type),
      browserHistoryDepth: 1000,
      version: null,
      cachedConfig: null,
    };
  },

  computed: {
    title() {
      return this.cachedConfig
        ? this.cachedConfig.locale[store.state.lang].entity || this.type
        : '';
    },
    meta() {
      return store.state.meta;
    },
    fieldsMeta() {
      return this.meta.components[this.type]?.fields || null;
    },
    isLocalDraft() {
      return this.id.startsWith('_temp');
    },
    draftData() {
      return store.mutate.getFormDraft(this.type, this.id)?.data;
    },
    entityDataA() {
      if (this.draftData) {
        const data = this.isLocalDraft ? {} : this.sourceData?.document.data;
        return { ...data, ...this.draftData };
      }

      return null;
    },
    entityDataB() {
      const data = this.isLocalDraft ? {} : this.sourceData?.document.data;
      return { ...data };
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === null) vm.browserHistoryDepth = 0;
      else vm.browserHistoryDepth++;
    });
  },

  created() {
    if (!this.draftData) {
      this.$router.go(-2);
    }
  },

  methods: {
    goBack() {
      if (this.browserHistoryDepth > 0) {
        this.browserHistoryDepth--;
        this.$router.go(-1);
      } else {
        this.gotoDraftsPage();
      }
    },

    gotoEdit() {
      this.$router.push({
        name: 'DataPage',
        params: {
          id: this.id,
          type: this.type,
        },
      });
    },

    gotoDraftsPage() {
      this.$router.push({
        name: 'Drafts',
        params: {
          id: this.id,
          type: this.type,
        },
      });
    },

    applyHistoryVersion(data) {
      store.mutate.storeFormDraft(this.type, this.id, data);
      this.gotoEdit();
    },

    editEntity(...args) {
      this.browserHistoryDepth++;
      this.$emit('editEntity', ...args);
    },

    async saveDraft(save) {
      await save();
      this.gotoDraftsPage();
    },

    dropDraft() {
      store.mutate.deleteFormDraft(this.type, this.id);
      this.gotoDraftsPage();
    },
  },
};
</script>

<style lang="scss">
.draft-page {
  &__footer {
    .ant-btn:not(:last-child) {
      margin-right: 15px;
    }
  }
}
</style>
