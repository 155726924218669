<template>
  <error-output :errors="errors">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label
      ref="label"
      :class="['autocomplete', { focused, disabled, readOnly }]"
      @click="onLabelClick"
    >
      <div class="autocomplete__wrapper">
        <span
          v-if="!selectValue || !selectValue.length"
          class="autocomplete__placeholder"
        >
          {{ placeholder }}
        </span>
        <span
          v-for="(key, index) of selectValue"
          :key="key"
          class="autocomplete__value-item"
        >
          {{ getTitleOfOptionByKey(key) }}
          <a-icon
            v-if="!disabled && !readOnly"
            type="close"
            @click.prevent="removeRef(index)"
          />
        </span>
        <a-select
          v-if="!readOnly"
          ref="focusOnMount"
          v-model="selectValue"
          showSearch
          class="autocomplete__input"
          :getPopupContainer="getPopupContainer"
          :defaultActiveFirstOption="false"
          :filterOption="false"
          :disabled="disabled"
          :mode="selectMode"
          :dropdownClassName="'autocomplete__dropdown ' + dropdownId"
          @search="onSearch"
          @focus="focused = true"
          @blur="focused = false"
          @dropdownVisibleChange="onDropdownVisibleChange"
        >
          <a-spin
            v-if="loadingKeys"
            slot="notFoundContent"
            size="small"
          />
          <div
            v-else
            class="autocomplete__nodata"
            slot="notFoundContent"
          >
            {{ $t('base.autocomplete_notFoundContent') }}
          </div>

          <a-select-option
            v-for="option of selectOptions"
            :key="option.key"
          >
            {{ getTitleOfOptionByKey(option.key) }}
          </a-select-option>
        </a-select>
      </div>
    </label>
  </error-output>
</template>

<script>
import { parseJson } from '@/helpers';
import ErrorOutput from '@/components/base/ErrorOutput';
import focusOnMount from '@/components/base/focusOnMount.mixin';
import lockScroll from '@/components/base/lockScroll.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';

export default {
  name: 'RefAutocomplete2',

  mixins: [focusOnMount, lockScroll, scrollElementInView],

  components: {
    ErrorOutput,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String, Array],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    parentType: {
      type: String,
      default: 'default',
    },
    placeholder: {
      type: String,
      default: '',
    },
    idAsLabel: {
      type: Boolean,
      default: false,
    },
    firstModalDepthLevel: {
      type: Boolean,
      default: false,
    },
    formState: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      search: '',
      focused: false,
      loadingKeys: 0,
      options: [],
    };
  },

  computed: {
    metaConfig() {
      // в publishable формах вместо config -- conf
      return this.config.config || this.config.conf;
    },
    multiple() {
      const multiple = this.metaConfig?.multiple;
      // в publishable формах multiple лежит не в config/conf а на уровень выше
      return multiple !== undefined ? multiple : this.config.multiple;
    },
    selectOptions() {
      return this.options.map(this.getOptionKeyAndTitleForSelect);
    },
    valuesDict() {
      let list = this.value;
      if (!list) list = [];
      else if (!Array.isArray(list)) list = [list];

      list = list.concat(this.options).reduce((dict, item) => {
        const key = this.getOptionKeyForSelect(item);
        dict[key] = item;
        return dict;
      }, {});

      return list;
    },
    selectValue: {
      get() {
        let value = this.value;

        if (value && !this.multiple) value = [this.getOptionKeyForSelect(value)];
        else if (value) value = value.map(this.getOptionKeyForSelect);
        else value = value || (this.multiple ? [] : undefined);

        return value;
      },
      set(value) {
        if (this.multiple) value = !value ? [] : value.map((v) => this.valuesDict[v]);
        else value = value && this.valuesDict[value];

        this.$emit('input', value);
      },
    },
    selectMode() {
      return this.multiple ? 'multiple' : 'default';
    },
    dropdownId() {
      return `autocomplete-${this._uid}`;
    },
  },

  methods: {
    async setFocus() {
      if (!this.multiple || !this.selectValue.length) {
        if (this.multiple) {
          this.$refs.focusOnMount.$el.querySelector('input').click();
        } else {
          this.$refs.focusOnMount.$el.click();
        }
      }
    },
    getOptionKeyForSelect(rawValue) {
      if (typeof rawValue === 'string') {
        return rawValue;
      }

      if (
        Object.keys(rawValue).length === 2 &&
        'title' in rawValue &&
        'value' in rawValue &&
        typeof rawValue.value === 'string'
      ) {
        return rawValue.value;
      }

      return rawValue.title;
    },
    getOptionKeyAndTitleForSelect(rawValue) {
      return {
        title: typeof rawValue === 'string' ? rawValue : rawValue.title,
        key: this.getOptionKeyForSelect(rawValue),
      };
    },
    getTitleOfOptionByKey(key) {
      let title = this.valuesDict[key];

      if (this.idAsLabel) {
        return key.split(':')[1];
      }

      const type = this.metaConfig?.url?.replace(/.*?\/handbooks\/(.*?)\/.*/, '$1');
      if (type) {
        const transKey = `handbooks.${type}.${key}`;
        title = this.$t(transKey);
        if (title === transKey) title = this.valuesDict[key];
      }

      return typeof title === 'string' ? title : title.title;
    },
    async onDropdownVisibleChange(visible) {
      // Update style because ant calculate in wrong and replace style attribute
      if (visible) {
        const styleId = `style-${this.dropdownId}`;
        let style = document.querySelector(`style#${styleId}`);
        if (!style) {
          style = document.createElement('style');
          style.id = styleId;
          style.type = 'text/css';
          document.querySelector('head').appendChild(style);
        } else if (style.children.length) {
          style.removeChild(style.children[0]);
        }

        const left = this.$refs.label.getBoundingClientRect().left + window.scrollX;

        style.appendChild(
          document.createTextNode(`
            .autocomplete__dropdown.${this.dropdownId} {
              left: ${left}px !important;
              width: ${getComputedStyle(this.$refs.label).width} !important;
            }
          `),
        );
        /////

        this.search = '';
        this.updateOptions();

        if (this.firstModalDepthLevel) {
          await this.scrollElementInView(this.$refs.label, this.getMarginsByRenderer('form_ref'));
          this.lockEntityFormScroll();
        }
      } else {
        this.lockEntityFormScroll(false);
      }
    },
    updateOptions() {
      let urls = this.metaConfig.urls;
      this.loadingKeys++;
      this.options = [];

      if (this.metaConfig.handbooks) {
        this.options = this.formState.handbooks[this.metaConfig.handbooks];
        this.loadingKeys--;
        return;
      }

      if (!urls) urls = [this.metaConfig.url];

      if (this.search) urls = urls.map((url) => `${url}?search=${this.search}`);

      Promise.all(
        urls.map(
          (url) =>
            new Promise((resolve) => {
              const xhr = new XMLHttpRequest();
              xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                  if (xhr.status === 200) {
                    let options = parseJson(xhr.response);
                    if (this.parentType === 'User' && this.config.name === 'roles') {
                      options = options.filter((item) => item !== 'ROLE_AUTHOR');
                    }

                    resolve(options);
                  } else {
                    this.emitError(
                      this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }),
                    );
                    resolve([]);
                  }
                }
              };

              xhr.open('GET', url, true);
              xhr.send();
            }),
        ),
      ).then((results) => {
        this.options = results.flat();
        this.loadingKeys--;
      });
    },
    getPopupContainer() {
      return document.querySelector('body');
    },
    onSearch(value) {
      this.search = value;
      this.updateOptions();
    },
    onLabelClick(event) {
      // Для не multiple селектов, чтобы не открывались повторно
      if (this.focused) event.preventDefault();
    },
    removeRef(index) {
      if (!this.multiple) {
        this.selectValue = null;
      } else {
        const refItems = [...this.selectValue];
        refItems.splice(index, 1);
        this.selectValue = refItems;
      }
    },
  },
};
</script>

<style lang="scss">
.autocomplete {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 0 4px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  line-height: 1.5;
  cursor: text;
  background-color: #fff;

  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: default;
    opacity: 1;
  }

  &__nodata {
    color: #aaa;
  }

  &__wrapper {
    position: relative;
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;

    & + div {
      left: -1px !important;
      right: -1px !important;
      width: auto !important;
    }
  }

  &__input {
    height: 100%;
    width: auto;
    top: 0;
    &.ant-select-open {
      width: auto;
    }
    .ant-select-search--inline {
      position: relative;
    }
  }

  &:hover,
  &.focused {
    border-color: #40a9ff;
  }

  &.focused {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &.disabled,
  &.readOnly {
    .autocomplete__value-item {
      padding-right: 8px;
    }
  }

  &__value-item {
    position: relative;
    flex-shrink: 0;
    max-width: 98%;
    display: inline-block;
    background: #efefef;
    padding: 1px 22px 0px 4px;
    margin: 3px 0 0 0;
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child) {
      margin-left: 4px;
    }

    .anticon {
      position: absolute;
      top: 6px;
      right: 4px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  &__placeholder {
    position: relative;
    flex-shrink: 0;
    max-width: 98%;
    display: inline-block;
    padding: 1px 0 0 4px;
    color: #bfbfbf;
    margin: 3px 0 0 0;
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.focused &__value-item {
    max-width: 60%;
  }

  &.focused &__placeholder {
    display: none;
  }

  &:not(.focused) {
    .ant-select-selection {
      max-width: 1px;
    }
    .autocomplete__input {
      margin: 0;
    }
  }

  &__wrapper > &__input {
    margin-left: 4px;
  }

  &__wrapper + div {
    height: 32;
  }

  .ant-select-selection__choice,
  .ant-select-selection-selected-value,
  .ant-select-arrow {
    display: none !important;
  }

  &__dropdown .ant-select-dropdown-menu {
    max-height: 5 * 32px;
  }

  .ant-select-dropdown {
    left: 0 !important;
    width: 100% !important;
  }

  .ant-select-selection,
  .ant-select-focused .ant-select-selection {
    background: none;
    border: none;
    box-shadow: none;
    min-height: 30px;
    height: 30px;
    padding-bottom: 1px;
  }

  .ant-select-selection__rendered {
    margin: 0;
  }

  .ant-select-disabled {
    display: none;
  }

  .ant-select-selection.ant-select-focused,
  .ant-select-search--inline .ant-select-search__field {
    width: 70px;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 0;
    width: 30px;
    &:after {
      display: none;
    }
  }
}

.entity-form.small {
  .autocomplete {
    min-height: 24px;
    padding: 0;

    &__wrapper {
      margin-bottom: -1px;
    }

    .ant-select-selection {
      height: 22px;
      min-height: 22px;
    }

    .ant-select-selection__rendered {
      margin-top: 0;
      line-height: 22px;
    }

    .ant-select-search {
      margin-top: 0;
      height: 22px;
      line-height: 22px;
    }

    .ant-select {
      top: 0;
    }

    &__value-item {
      height: 22px;
      padding: 0px 24px 1px 8px;
      margin-top: 0;
      margin-right: 2px;
      margin-bottom: 1px;
      vertical-align: top;

      .anticon {
        top: 4px;
      }
    }
  }
}
</style>
