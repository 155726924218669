<template>
  <component
    :is="rootComponent"
    :sidebar="sidebarItems"
    :selectedKeys="selectedKeys"
    :inlineIndent="36"
    :verticalWidth="310"
    @click="itemSelect"
  />
</template>

<script>
import store from '@/store';
import { bus, hashCode } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import SidebarVerticalRoot from './SidebarVerticalRoot';
import SidebarHorizontalRoot from './SidebarHorizontalRoot';

export default {
  name: 'TheSidebar',
  components: {
    SidebarVerticalRoot,
    SidebarHorizontalRoot,
  },

  props: {
    verticalWidth: {
      type: [Number, String],
      default: 310,
    },
    forcedMode: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedKeys: [],
    };
  },

  computed: {
    entitiesList() {
      return Object.values(store.state.meta.entities)
        .map((entity) => store.state.meta.components[entity.name])
        .filter((entity) => entity.operations.TABLE)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name);
    },

    processesList() {
      return Object.values(store.state.meta.processes)
        .map((process) => store.state.meta.processes[process.name])
        .filter((process) => process.operations.TABLE)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => name);
    },

    rootComponent() {
      return `sidebar-${this.forcedMode || store.state.menuMode}-root`;
    },

    allEntitiesSection() {
      if (!Object.keys(this.entitiesList).length) return [];

      const entities = [];

      this.entitiesList.forEach((code) => {
        const meta = store.state.meta.entities.find(({ name }) => name === code);

        const menuItem = {
          code,
          url: code,
          type: 'page',
          title: FormConfigService.getFormConfig(code).locale[store.state.lang].entity || code,
          ...(this.config.override[code] || {}),
        };

        menuItem.id = this.getMenuItemId(menuItem, '__menu-all-entites');

        if (meta.publishable) {
          menuItem.customprops = {
            listMeta: `/forms/qury.${menuItem.code}.listEditor`,
            getMeta: `/forms/mutation.${menuItem.code}.get`,
            createMeta: `/forms/mutation.${menuItem.code}.create`,
            updateMeta: `/forms/mutation.${menuItem.code}.updateEditor`,
          };
        }

        entities.push(menuItem);
      });

      return [
        {
          id: '__menu-all-entites',
          type: 'section',
          title: this.$t('sidebar.allEntities'),
          children: entities,
        },
      ];
    },

    allProcessesSection() {
      return Object.keys(this.processesList).length
        ? [
            {
              id: '__menu-all-processes',
              type: 'section',
              title: this.$t('sidebar.allProcesses'),
              children: this.processesList.map((code) => {
                const menuItem = {
                  code,
                  url: code,
                  type: 'process',
                  isProcess: true,
                  title:
                    FormConfigService.getProcessFormConfig(code).locale[store.state.lang].entity ||
                    code,
                };

                menuItem.id = this.getMenuItemId(menuItem, '__menu-all-processes');

                return menuItem;
              }),
            },
          ]
        : [];
    },

    sidebarItems() {
      const sidebarItems = [];

      if (this.config.showAllProcessesMenu) {
        sidebarItems.push(...this.allProcessesSection);
      }

      if (this.config.showAllEntitiesMenu) {
        sidebarItems.push(...this.allEntitiesSection);
      }

      if (this.config.menu) {
        sidebarItems.push(...this.config.menu.children);
      }

      return sidebarItems;
    },

    flatPagesList() {
      return this.sidebarItems
        .map((topSection) =>
          topSection.children
            .map((child) => (child.type === 'section' ? child.children : [child]))
            .flat(),
        )
        .flat();
    },
  },

  watch: {
    $route() {
      this.setKeyFromCurrentRoute();
    },
  },

  created() {
    bus.$on('gotoFirstPage', this.gotoFirstPage);
    this.setKeyFromCurrentRoute();
  },

  methods: {
    getMenuItemId(entry, parentId) {
      const hash = entry.id || hashCode(entry.title || entry.url || entry.code);
      return `${parentId}-${hash}`;
    },

    setKeyFromCurrentRoute() {
      let itemType = this.$route.fullPath.match(/\/(.*?)\//)?.[1] || 'page';
      if (itemType === 'edit') itemType = 'page';

      const activeItem = this.flatPagesList.find(
        (item) => item.type === itemType && item.url === this.$route.params.type,
      );

      if (activeItem) {
        this.selectedKeys = [`${itemType}:${this.$route.params.type || this.$route.params.pageId}`];
      } else {
        this.selectedKeys = [];
        if (this.$route.meta.redirectIfNotExist) {
          this.gotoFirstPage();
        }
      }

      store.mutate.setActiveSidebarItem(activeItem);
    },

    itemSelect({ key }) {
      const [type, value] = key.split(':');

      const activeItem = this.flatPagesList.find(
        (item) => item.type === type && item.url === value,
      );

      let target;
      if (type === 'url') {
        if (/^(http|\/\/)/.test(value)) {
          window.open(value, '_blank');
        } else {
          target = {
            path: value,
          };
        }
      } else {
        const query = activeItem?.query ? `?${activeItem.query}` : '';
        target = {
          path: `/${type}/${value}${query}`,
        };
      }

      if (target) {
        this.$router.push(target).catch(() => {});
        this.$emit('toggleMenu', false);
      }
    },

    getFirstPageId() {
      if (!this.flatPagesList.length) {
        this.emitError(this.$t('sidebar.errorEmpty'));
      }

      return this.flatPagesList[0];
    },

    gotoFirstPage() {
      const page = this.getFirstPageId();
      let path = `/${page.type}`;
      if (path !== 'errors') path = `${path}/${page.code}`;

      this.$router.push({ path });
    },
  },
};
</script>
