import store from '@/store';
import { getTempId, parseJson, hashCode } from '@/helpers';

export default class MenuConfig {
  constructor(config) {
    this.id = config?.id || this.findDraftId() || getTempId();
    this.data = this.actualizeData(parseJson(config?.data.config));
  }

  findDraftId() {
    const allConfigsDrafts = store.state.formsDrafts.Config || {};
    return Object.entries(allConfigsDrafts).find((draft) => draft[1].data.title === 'main')?.[0];
  }

  getSidebarConfig() {
    return this.data.sidebar;
  }

  getAdditionalSidebarConfig() {
    return this.data?.additionalSidebar;
  }

  actualizeData(raw = {}) {
    const data = {
      sidebar: {
        showAllProcessesMenu: raw.sidebar?.showAllProcessesMenu ?? true,
        showAllEntitiesMenu: raw.sidebar?.showAllEntitiesMenu ?? true,
        menu: this.actualizeSidebarSection(raw.sidebar?.custom, true),
        override: raw.sidebar?.override || {},
      },
    };
    if (raw?.additionalSidebar) {
      data.additionalSidebar = raw.additionalSidebar;
    }

    if (store.state.user.isConstructor) {
      data.sidebar.menu.children.push(
        ...this.actualizeSidebarSection(raw.sidebar?.constructor, true).children,
      );
    }

    return data;
  }

  getSidebarItemType(item, topLevel) {
    let type = item.type;
    if (topLevel || item.dropdown) {
      type = 'section';
    } else if (!type) {
      type = item.code ? 'page' : 'url';
    }

    return type;
  }

  getEntryId(entry, parent) {
    const hash = entry.id || hashCode(entry.title || entry.url || entry.code);
    return `${parent?.id || 'menu'}-${hash}`;
  }

  actualizeSidebarSection(section = {}, topLevel = false) {
    section.children = section.children || section.childs;
    delete section.childs;

    if (topLevel) {
      section = {
        id: '__menu-root',
        type: 'root',
        children: Array.isArray(section) ? section : section.children || [],
      };
    } else {
      let dropdown;
      if (section.dropdown) {
        dropdown = section.dropdown;
      } else {
        dropdown = !!section.children;
      }

      section = {
        id: this.getEntryId(section),
        type: 'section',
        dropdown: section.dropdown || dropdown,
        title: section.title || '',
        icon: section.icon || '',
        children: section.children || [],
      };
    }

    section.children = section.children.map((child) => {
      const type = this.getSidebarItemType(child, topLevel);
      if (type === 'section') {
        return this.actualizeSidebarSection(child);
      }

      let customprops = child.customprops;
      customprops = typeof customprops === 'object' ? customprops : {};

      const [url, query] = (child.url || child.code).split('?');

      return {
        id: this.getEntryId(child, section),
        type,
        title: child.title || '',
        icon: child.icon || '',
        code: child.code,
        url,
        query,
        customprops,
      };
    });
    return section;
  }
}
