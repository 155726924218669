import Vue from 'vue';
import { DESKTOP_BREAKPOINT } from '@/constants';
import { XHR } from '@/helpers/xhr';
import { storage } from '@/helpers/storage';
import { isHighDensityDisplay, isRetinaDisplay } from '@/helpers/display';
import User from './User';

function getFormsDraftsFromStorage() {
  return storage.get('formsDrafts') || {};
}

const state = Vue.observable({
  appVersion: process.env.VUE_APP_FE_VERSION,
  user: new User(),
  windowWidth: 0,
  isDesktop: false,
  formsDrafts: getFormsDraftsFromStorage(),
  pages: [],
  lang: storage.get('lang'),
  darkTheme: storage.get('darkTheme') || false,
  menuMode: storage.get('menuMode') || 'vertical',
  activeSidebarItem: undefined,
  display: {
    isHighDensity: isHighDensityDisplay(),
    isRetina: isRetinaDisplay(),
  },
  apolloClient: null,
  formConfigs: {},
  meta: null,
  embedAutofocus: null,
  transProvider: null,
});

function saveFormsDraftsToStorage() {
  storage.set('formsDrafts', state.formsDrafts);
}

const mutations = {
  storeFormDraft(type, id, data) {
    const draft = {
      changeTime: new Date(),
      data,
    };

    if (!state.formsDrafts[type]) Vue.set(state.formsDrafts, type, {});
    Vue.set(state.formsDrafts[type], id || '', draft);
    saveFormsDraftsToStorage();
  },

  getFormTypeDrafts(type) {
    return state.formsDrafts[type] || {};
  },

  getFormDraft(type, id = '') {
    return state.formsDrafts[type]?.[id] || null;
  },

  deleteFormDraft(type, id) {
    if (state.formsDrafts[type]) Vue.delete(state.formsDrafts[type], id);
    saveFormsDraftsToStorage();
  },

  deleteAllDrafts() {
    Vue.set(state, 'formsDrafts', {});
    saveFormsDraftsToStorage();
  },

  windowWidthChanged(width) {
    state.windowWidth = width;
    state.isDesktop = width >= DESKTOP_BREAKPOINT;
  },

  async logout() {
    await XHR.post(process.env.VUE_APP_LOGOUT_URL);
  },

  setMeta(meta) {
    state.meta = meta;
  },

  setPageList(pages) {
    state.pages = pages;
  },

  switchDarkTheme(value) {
    state.darkTheme = value;
    storage.set('darkTheme', value);
  },

  switchMenuMode(value) {
    state.menuMode = value;
    storage.set('menuMode', value);
  },

  setActiveSidebarItem(activeItem) {
    state.activeSidebarItem = activeItem;
  },

  setApolloClient(client) {
    state.apolloClient = client;
  },

  setLang(lang) {
    state.lang = lang;
    storage.set('lang', lang);
  },
};

export default {
  state,
  mutate: mutations,
};
