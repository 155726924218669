<template>
  <a-config-provider :getPopupContainer="getPopupContainer">
    <div
      v-if="appSettings.params || isAuthPage"
      id="app"
      class="app"
    >
      <a-layout class="app__content-wrap">
        <the-additional-sidebar
          v-if="showHeaderAndSidebar && config?.data?.additionalSidebar && desktop"
          :config="config.getAdditionalSidebarConfig()"
        />
        <the-sidebar
          v-if="showHeaderAndSidebar && config && desktop"
          :config="config.getSidebarConfig()"
          @toggleMenu="toggleMenu"
        />
        <a-layout-content
          v-if="formConfigs || isAuthPage"
          class="app__content"
        >
          <the-header
            v-if="showHeaderAndSidebar && menuMode === 'vertical'"
            @logout="logout"
            @openAppSettings="openAppSettings"
          />
          <router-view
            @editEntity="editEntity"
            @logout="logout"
          />
        </a-layout-content>
      </a-layout>

      <a-modal
        :class="{ fullscreenModal: fullscreenModal && !isActionModal }"
        :visible="isModalVisible"
        :bodyStyle="modalBodyStyle"
        :title="null"
        :footer="null"
        :closable="false"
        width="1170px"
        transitionName="none"
        maskTransitionName="none"
        @cancel="closeModalEditing"
      >
        <action-form
          v-if="isModalVisible && isActionModal"
          :id="entityInEditId"
          :type="entityInEditType"
          @close="closeModalEditing"
        />
        <component
          :is="customFormComponent"
          v-else-if="isModalVisible && isCustomModal"
          :id="entityInEditId"
          :type="entityInEditType"
          :fullscreen="fullscreenModal"
          :contentVisible="isModalContentVisible && sidebarItemActive"
          @editFullEntity="switchFullscreenModal"
          @editEntity="editEntity"
          @close="closeModalEditing"
        />
        <publishable-entity-modal
          v-else-if="isModalVisible && sidebarItemActive && isPublishableEntityModal"
          :id="entityInEditId"
          :type="entityInEditType"
          :fullscreen="fullscreenModal"
          :contentVisible="isModalContentVisible && sidebarItemActive"
          @editFullEntity="switchFullscreenModal"
          @editEntity="editEntity"
          @close="closeModalEditing"
        />
        <entity-modal
          v-else-if="isModalVisible"
          :id="entityInEditId"
          :type="entityInEditType"
          :fullscreen="fullscreenModal"
          :contentVisible="isModalContentVisible && sidebarItemActive"
          @editFullEntity="switchFullscreenModal"
          @editEntity="editEntity"
          @close="closeModalEditing"
        />
      </a-modal>
      <div
        v-if="config && !desktop && showHeaderAndSidebar"
        class="app-mobile-menu"
        :class="{ show: mobileMenuActive }"
      >
        <div class="app-mobile-menu__header">
          <a-icon
            class="app-mobile-menu__close"
            type="close"
            @click="toggleMenu(false)"
          />
          <the-header
            @logout="logout"
            @openAppSettings="openAppSettings"
          />
        </div>
        <the-sidebar
          v-scroll-lock="mobileMenuActive"
          :config="config.getSidebarConfig()"
          forcedMode="vertical"
          verticalWidth="100%"
          @toggleMenu="toggleMenu"
        />
      </div>
      <app-settings-modal
        v-if="user.isConstructor"
        v-model="isAppSettingModalActive"
        @logout="logout"
      />
    </div>
  </a-config-provider>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import store from '@/store';
import { bus, metaQueryParser } from '@/helpers';
import { appSettings } from '@/AppSettings';
import META_QUERY from '@/queries/meta';
import CONFIG_QUERY from '@/queries/config';
import EntitySevice from '@/services/EntityService';
import FormConfigService from '@/services/FormConfigService';
import lockScroll from '@/components/base/lockScroll.mixin';
import TheHeader from '@/components/header/TheHeader.vue';
import TheSidebar from '@/components/sidebar/TheSidebar.vue';
import TheAdditionalSidebar from '@/components/additionalSidebar/TheAdditionalSidebar.vue';
import EntityModal from '@/components/edit-form/EntityModal.vue';
import ActionForm from '@/components/action-form/ActionForm.vue';
import AppSettingsModal from '@/components/header/AppSettingsModal.vue';
import SoB2bOrder from '@/components/custom-forms/SoB2bOrder/SoB2bOrder';
import PublishableEntityModal from '@/components/publishable-entity/PublishableEntityModal.vue';
import 'ant-design-vue/dist/antd.less';
import MenuConfig from './MenuConfig';
import './styles/dark.theme.scss';
import './styles/globals.scss';

export default {
  name: 'App',

  mixins: [lockScroll],

  components: {
    TheSidebar,
    TheAdditionalSidebar,
    TheHeader,
    EntityModal,
    PublishableEntityModal,
    ActionForm,
    AppSettingsModal,
    SoB2bOrder,
  },

  apollo: {
    meta: {
      ...META_QUERY,
      error(error) {
        this.emitError(this.$t('app.error.meta'), error.message);
      },
      skip() {
        return this.isAuthPage;
      },
    },
    rawConfig: {
      ...CONFIG_QUERY,
      variables() {
        return {
          name: 'main',
        };
      },
      update({ table }) {
        return table.documents[0];
      },
      skip() {
        return !appSettings.params;
      },
      error(error) {
        this.emitError(this.$t('app.error.mainConfig'), error.message);
      },
    },
  },

  data() {
    return {
      documentBody: document.querySelector('body'),
      errors: [],
      mobileMenuActive: false,
      windowWidth: window.innerWidth,
      entityInEditId: null,
      entityInEditType: null,
      isAppSettingModalActive: false,
      config: undefined,
      fullscreenModal: false,
      formConfigs: null,
      appSettings,

      isModalVisible: false,
      isModalContentVisible: false,
      // ...
      store,
    };
  },

  computed: {
    user() {
      return store.state.user;
    },
    isAuthPage() {
      return this.$route.name === 'AuthPage';
    },
    showHeaderAndSidebar() {
      return !this.isAuthPage && appSettings.params && this.formConfigs;
    },
    desktop() {
      return store.state.isDesktop;
    },
    customFormComponent() {
      return store.state.activeSidebarItem?.customprops?.customFormComponent;
    },
    isActionModal() {
      return (
        this.$route.params.action && store.state.activeSidebarItem && !this.customFormComponent
      );
    },
    isCustomModal() {
      return !!this.customFormComponent;
    },
    modalBodyStyle() {
      return {
        height: 'calc(80vh - 50px)',
        maxWidth: '1170px',
        maxHeight: 'none',
        width: '100%',
        backgroundColor: '#f9f9f9',
      };
    },
    menuMode() {
      return store.state.menuMode;
    },
    sidebarItemActive() {
      return !!store.state.activeSidebarItem;
    },
    isPublishableEntityModal() {
      const { id, type } = this.$route.params;

      return (
        this.$route.name === 'DataPage' &&
        id &&
        type &&
        (store.state.meta.components[store.state.activeSidebarItem.code]?.publishable ||
          store.state.activeSidebarItem?.customprops?.listMeta)
      );
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        // Логаут пользователей без ROLE_ADMIN
        if (
          !this.isAuthPage &&
          store.state.user.isLoaded &&
          !store.state.user.roles.includes('ROLE_ADMIN')
        ) {
          store.mutate.logout();
          this.$router.push({ name: 'AuthPage' });
          return;
        }

        // Страница с таблицей
        if (to.name === 'DataPage') {
          const { id, type, rowData } = to.params;

          this.entityInEditId = id || null;
          this.entityInEditType = id ? type : null;

          if (id?.startsWith('vid-') && !rowData) {
            this.$router.push({
              name: 'DataPage',
              params: { type: this.$route.params.type },
            });
            return;
          }
        }

        // Страница с таблицей + открытая форма сущности другого типа (не равного типу таблицы)
        if (to.name === 'DataPagePopup') {
          const { pId, pType } = to.params;
          this.entityInEditId = pId || null;
          this.entityInEditType = pId ? pType : null;
        }

        // Страница с таблицей + открытая форма модели
        if (to.name === 'DataPageModel') {
          const { type } = to.params;
          const table = await EntitySevice.getTable({
            type: 'EntityConfig',
            page: 0,
            pageSize: 1,
            filters: [
              {
                field: 'title',
                operator: 'EQUALS',
                value: type,
              },
            ],
            orders: [],
          });

          if (table.length) {
            this.entityInEditId = table[0].id;
            this.entityInEditType = 'EntityConfig';
          }
        }

        // So modal mounted hook will be called *after* modal wrapper is rendered
        // Otherwise dom query selector in EmbedTable can't find modal-wrapper
        if (this.entityInEditType) {
          this.isModalVisible = true;
          this.$nextTick(() => {
            this.isModalContentVisible = true;
          });
        }
      },
    },
    menuMode: {
      immediate: true,
      handler(value, oldValue) {
        this.documentBody.classList.toggle(`menu--${oldValue}`, false);
        this.documentBody.classList.toggle(`menu--${value}`, true);
      },
    },
    rawConfig(value) {
      if (value !== undefined) {
        this.updateConfig();

        if (!this.formConfigs) {
          this.loadFormConfigs();
        }
      }
    },
    user: {
      deep: true,
      handler() {
        this.updateMetaOperations();
      },
    },
    async meta(value, oldValue) {
      if (value !== oldValue) {
        store.mutate.setMeta(value);
        this.updateMetaOperations();
        await this.loadFormConfigs();
      }
    },
  },

  async created() {
    store.state.transProvider = this;

    const vee = await import('vee-validate');
    import('./vee-validate');
    Vue.component('ValidationProvider', vee.ValidationProvider);
    Vue.component('ValidationObserver', vee.ValidationObserver);

    bus.$on('appSettingsLoadError', this.openAppSettings);
    bus.$on(['entityCreated', 'entityUpdated', 'entityDeleted'], ({ type }) => {
      if (['JEmbed', 'JHandbook', 'EntityConfig'].includes(type)) {
        this.$apollo.queries.meta.refetch();
      }
    });

    await appSettings.load(store, this);
    await store.state.user.loadInfo();

    document.addEventListener(
      'play',
      (e) => {
        const audios = document.getElementsByTagName('audio');
        for (let i = 0, len = audios.length; i < len; i++) {
          if (audios[i] !== e.target) {
            audios[i].pause();
          }
        }
      },
      true,
    );

    // Open links inside wysiwyg
    document.addEventListener('click', ({ target, path, ctrlKey }) => {
      if (ctrlKey && target.tagName === 'A') {
        if (path.some((p) => p.classList?.contains('toastui-editor-contents'))) {
          path.some((p) => p.classList?.contains('toastui-editor-contents'));
          window.open(target.href, '_blank').focus();
        }
      }
    });
  },

  mounted() {
    bus.$on('editEntity', this.editEntity);
    bus.$on('toggleMenu', this.toggleMenu);
    bus.$on('mainConfigUpdated', this.updateConfig);
    bus.$on('error', this.parseError);
    bus.$on('logout', this.logout);
    window.addEventListener('resize', this.onResize);
    store.mutate.windowWidthChanged(window.innerWidth);
  },

  methods: {
    updateMetaOperations() {
      if (!this.meta || !this.user?.isLoaded) return;

      metaQueryParser.updateOperations(this.meta, this.user);
      this.updateConfig();
    },

    async loadFormConfigs() {
      if (appSettings.params) {
        this.formConfigs = await FormConfigService.load();
      }
    },

    updateConfig() {
      this.config = new MenuConfig(this.rawConfig);
    },

    toggleMenu(state = null) {
      this.mobileMenuActive = state !== null ? state : !this.mobileMenuActive;
      this.lockScroll(document.body, this.mobileMenuActive);
    },

    editEntity(type, id, full = false, reopen = false, rowData = null) {
      if (reopen) {
        this.closeModalEditing();
        this.$nextTick(() => {
          this.editEntity(type, id);
        });

        return;
      }

      if (store.state.activeSidebarItem.customprops?.customFormComponent) {
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            id,
            action: store.state.activeSidebarItem.customprops?.customFormComponent,
          },
        });
      } else {
        this.$router
          .push({
            name: 'DataPage',
            params: { type, id, rowData },
          })
          .catch(() => {});
      }

      if (full) this.editFullEntity();
    },

    editFullEntity() {
      this.toggleMenu(false);
      this.$router
        .replace({
          name: 'EntityPage',
          params: {
            type: this.entityInEditType,
            id: this.entityInEditId,
          },
        })
        .catch(() => {});

      this.closeModalEditing({ keepUrl: true });
    },

    switchFullscreenModal() {
      this.fullscreenModal = !this.fullscreenModal;
      this.$nextTick(() => {
        bus.$emit('monaco.updatelayout');
      });
    },

    replaceUrlAfterModalClose() {
      this.$router.replace({
        name: 'DataPage',
        params: { type: this.$route.params.type },
      });
    },

    closeModalEditing({ keepUrl = false } = {}) {
      if (!keepUrl) this.replaceUrlAfterModalClose();

      this.entityInEditId = null;
      this.entityInEditType = null;

      if (this.$route.name !== 'DataPageModel') {
        bus.$emit('refetchTable');
      }

      this.isModalContentVisible = false;
      this.$nextTick(() => {
        this.isModalVisible = false;
      });
    },

    onResize() {
      store.mutate.windowWidthChanged(window.innerWidth);
    },

    // Force ant select/datepicker popups to not scroll with page
    getPopupContainer(trigger) {
      return trigger ? trigger.parentNode : document.querySelector('body');
    },

    async logout(query = {}) {
      const path = this.$router.getRoutes().find((route) => route.name === 'AuthPage').path;
      query = Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      await store.mutate.logout();
      window.location.href = query ? `${path}?${query}` : path;
    },

    parseError(error) {
      const errorIndex = this.errors.findIndex(
        (e) => e.message === error.message && e.description === error.description,
      );

      // because apollo queries calls error callback twice
      if (errorIndex === -1) {
        this.errors.push(error);
      }

      this.showErrors();
    },

    showErrors: debounce(function showErrors() {
      this.errors.forEach(({ message, description, type = 'error', btn }) => {
        this.$notification[type]({
          message,
          description,
          btn,
        });
      });

      this.errors = [];
    }, 100),

    openAppSettings() {
      this.isAppSettingModalActive = true;
    },
  },
};
</script>

<style lang="scss">
* {
  transition: none !important;
}

.app {
  height: 100%;

  &__content {
    @include scrollbars();
    padding: 10px 0px 0;
    overflow-y: auto;
  }

  & > .ant-layout {
    height: 100%;
  }
}

// Fix invisible text in wysiwyg (tui-editor) on Ctrl-CLick
.ProseMirror-selectednode {
  &::selection,
  ::selection {
    color: inherit;
  }
}

.ant-modal {
  max-width: calc(100% - 32px);
}

.spin-wrap {
  min-height: 300px;
}

.ant-popover,
.ant-dropdown {
  animation-duration: 0s !important;
}

.app-mobile-menu {
  @include fixedBox();
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px 12px;
    height: 60px;
    background: #fff;
  }

  &__close {
    width: 30px;
    font-size: 16px;
  }

  .ant-layout-sider {
    @include scrollbars();
    flex: 1 1 auto !important;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
}

.ant-tabs-tabpane {
  transition: none !important;
}

.ant-btn-secondary,
.ant-btn-secondary:focus {
  background: #f0f0f0;
}

.ant-layout.ant-layout-has-sider .app__content.ant-layout-content {
  height: 100%;
  min-width: 0;
}

.ant-modal {
  top: auto;
  padding-bottom: 0;
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
}

.ant-modal-body {
  padding: 0;
}

.ant-select-dropdown-menu.ant-select-dropdown-menu-root,
.gantt_layout_cell {
  @include scrollbars();
}

.ant-table {
  th + th .ant-table-header-column:before {
    border-left: 1px solid #e8e8e8;
    content: '';
    position: absolute;
    left: -16px;
    width: 1px;
    height: 1.6em;
  }

  .ant-table-header-column {
    position: relative;
  }
}
.theme--dark {
  th + th .ant-table-header-column:before {
    border-left-color: #777;
  }
}

.ant-modal-root.fullscreenModal {
  .ant-modal {
    top: 0;
    width: 100vw !important;
    max-width: none;
    margin: 0;
    padding: 0;
  }
  .ant-modal-body {
    max-width: 100% !important;
    height: 100vh !important;
  }
  .entity-form__content {
    max-width: 100%;
  }
  .entity-modal__wrap,
  .entity-view {
    max-width: none;
  }
}

@media (min-width: $tabletBreakpoint) {
  .app {
    &__content {
      padding: 35px 0px 0;
    }
  }

  .app-mobile-menu {
    padding: 0 20px 10px;

    &__header {
      height: 86px;
      padding: 5px 10px;
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .app {
    &__content-wrap {
      padding-top: 0;
      height: 100%;

      & > .ant-layout-sider {
        @include scrollbars();
        height: 100%;
        overflow-y: auto;
      }
    }

    &__content {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
