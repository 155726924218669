<template>
  <a-layout-sider
    theme="light"
    :width="verticalWidth"
    class="nav-additional-sider"
  >
    <a-menu
      v-if="showTopSection"
      mode="vertical"
      @click="handleClick('topSection', $event.key)"
    >
      <a-menu-item
        v-for="(menuItem, index) of config.topSection"
        :key="index"
      >
        <img
          class="additional-sider__icon"
          :src="getImgUrl(menuItem.icon)"
          :alt="menuItem.alt"
        />
      </a-menu-item>
    </a-menu>
    <a-menu
      v-if="showBottomSection"
      mode="vertical"
      @click="handleClick('bottomSection', $event.key)"
    >
      <a-menu-item
        v-for="(menuItem, index) of config.bottomSection"
        :key="index"
      >
        <img
          class="additional-sider__icon"
          :src="getImgUrl(menuItem.icon)"
          :alt="menuItem.alt"
        />
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
export default {
  name: 'TheAdditionalSidebar',

  props: {
    verticalWidth: {
      type: [Number, String],
      default: 45,
    },
    forcedMode: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedKeys: [],
    };
  },
  computed: {
    showTopSection() {
      return this.config && this.config.topSection.length;
    },
    showBottomSection() {
      return this.config && this.config.bottomSection.length;
    },
  },
  methods: {
    handleClick(section, key) {
      const menuItem = this.config[section][key];
      if (menuItem.href.startsWith('/') && menuItem.href !== this.$router.currentRoute.fullPath) {
        this.$router.push({ path: menuItem.href });
      } else if (!menuItem.href.startsWith('/')) {
        window.location.href = menuItem.href;
      }
    },
    getImgUrl(iconTitle) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/icons/${iconTitle}.svg`);
    },
  },
};
</script>

<style lang="scss">
.nav-additional-sider {
  border-right: 1px solid #e8e8e8;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu-vertical {
      border: none;
      .ant-menu-item {
        padding: 10px;
        margin: 0;
        height: 44px;
        line-height: 24px;
        .additional-sider__icon {
          width: 24px;
          height: 24px;
        }
        .anticon {
          margin: 0;
          font-size: 24px;
        }
      }
      .ant-menu-item-active {
        color: #000;
        background-color: rgb(248, 249, 250);
      }
    }
  }
}
</style>
